<template>
  <div class="content-wrapper user-page">
    <h1 class="mb-5">Users</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <b-button
            size="sm"
            class="mb-2"
            v-b-toggle.collapse-advancedFilter
            :disabled="advancedFilter_visible"
          >
            <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
          </b-button>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-partners>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New member
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-collapse
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-form-select
              v-model="companyListSelected"
              :options="filterCompanyList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="areaTypeListSelected"
              :options="filterAreaTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="departmentTypeListSelected"
              :options="filterDepartmentTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="clusterTypeListSelected"
              :options="filterClusterTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="roleTypeListSelected"
              :options="filterRoleTypeList"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
      <b-card>
        <b-table
          hover
          :items="partnerList"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(role)="row">
            {{ getRoleName(row.item.id_role) }}
          </template>
          <template #cell(area)="row">
            {{ getAreaName(row.item.id_area) }}
          </template>
          <template #cell(department)="row">
            {{ getDepartmentName(row.item.id_department) }}
          </template>
          <template #cell(cluster)="row">
            {{ getClusterName(row.item.id_cluster) }}
          </template>
          <template #cell(company)="row">
            {{ getCompanyList(row.item.id_company) }}
          </template>
          <template #cell(target)="row">
            <b-progress
              :value="row.item.target"
              max="100"
              variant="primary"
              height=".5rem"
              class="progress-bar-wrapper"
            ></b-progress>
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>
              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-partners
                @click="
                  currentItem = row.item;
                  tmpItem = JSON.parse(JSON.stringify(row.item));
                "
                >Edit</b-dropdown-item
              >
              <b-dropdown-item href="#" @click="updateState(row.item)">{{
                row.item.attivo ? "Block" : "Unblock"
              }}</b-dropdown-item>
              <b-dropdown-item
                href="#"
                @click="
                  currentItem = row.item;
                  showDeleteModal = true;
                "
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #cell(attivo)="row">
            <b-icon-unlock-fill v-if="row.item.attivo"></b-icon-unlock-fill>
            <b-icon-lock-fill v-else></b-icon-lock-fill>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-partners"
      @shown="loadUserData"
      right
      shadow
      lazy
      @hidden="resetSidebar"
      style="z-index:901 !important"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">Edit partner</h4>
        <h4 v-else>New partner</h4>
        <div
          class="upload-photo-cicrle"
          v-bind:style="{
            backgroundImage: 'url(' + backgroundImage() + ')',
          }"
        >
          <b-avatar
            v-if="tmpItem.avatar_url.length == 0"
            size="6rem"
          ></b-avatar>
          <b-form-file
            class="button-file photo-button"
            v-model="file_photo"
            accept="image/jpeg, image/png, image/gif"
          >
            <template #placeholder>
              <b-icon-camera-fill />
            </template>
            <template #file-name>
              <b-icon-camera-fill />
            </template>
          </b-form-file>
          <b-icon
            v-if="loadingFile"
            class="loading-file"
            icon="arrow-clockwise"
            animation="spin"
            font-scale="2"
          ></b-icon>
        </div>
        <b-tabs lazy content-class="mt-3" class="mt-3">
          <b-tab title="Dati generali" active class="mx-3 mt-4">
            <b-row>
              <b-col>
                <b-form-group label="Nome">
                  <b-form-input v-model="tmpItem.nome" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Cognome">
                  <b-form-input v-model="tmpItem.cognome" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Email">
              <b-form-input v-model="tmpItem.email" />
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group label="Default password">
                  <b-form-input disabled :value="tmpItem.temporaryPassword" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Permalink">
                  <b-form-input v-model="tmpItem.permalink"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Company">
              <b-form-select
                @change="setNullRole"
                v-model="tmpItem.id_company"
                :options="filterCompanyList"
              ></b-form-select>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group label="Role">
                  <b-form-select
                    v-model="tmpItem.id_role"
                    :options="filterRoleTypeList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Parent">
                  <b-form-select
                    v-model="tmpItem.id_parent"
                    :options="partnerListParent"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-form-checkbox v-model="user_advanced" disabled>
              Selezioni avanzate
            </b-form-checkbox> -->
            <b-form-group label="Area">
              <b-form-select
                v-model="tmpItem.id_area"
                :options="filterAreaTypeList"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Department">
              <b-form-select
                v-model="tmpItem.id_department"
                :options="filterDepartmentTypeList"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Cluster">
              <b-form-select
                v-model="tmpItem.id_cluster"
                :options="filterClusterTypeList"
              ></b-form-select>
            </b-form-group>
            <div class="mt-5 mb-5">
              <b-button
                v-if="isLoading"
                squared
                variant="primary"
                size="lg"
                class="big-button"
                disabled
                ><b-spinner small type="grow"></b-spinner> Loading...</b-button
              >
              <b-button
                v-else
                squared
                variant="primary"
                size="lg"
                class="big-button"
                @click="saveItem"
                >Save</b-button
              >
            </div>
          </b-tab>
          <b-tab v-if="currentItem" title="Corsi abbinati" class="mx-3 mt-4">
            <b-overlay :show="showTabOverlay">
              <div class="d-flex justify-content-end mb-3">
                <view-lang-select v-model="view_lang" :options="languages" />
              </div>
              <b-form-group
                label="Campaign"
                v-if="computed_user_campaigns.length"
              >
                <b-form-select
                  v-model="user_campaign"
                  :options="computed_user_campaigns"
                  @input="loadUserSections"
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                v-show="user_campaign != 0"
                label="Sezione"
                v-if="computed_user_sections.length"
              >
                <b-form-select
                  v-model="user_section"
                  :options="computed_user_sections"
                  @input="loadUserChapter"
                ></b-form-select>
              </b-form-group>
              <b-form-group label="Capitoli">
                <div class="checkbox-container">
                  <div class="w-100 d-flex pb-4 pt-2">
                    <b-form-checkbox
                      v-model="tuttiCapitoliSelezionati"
                      switch
                      size="lg"
                    >
                    </b-form-checkbox>
                    <label style="margin: 0.25rem 0 0 0">Seleziona tutto</label>
                  </div>

                  <b-row
                    v-for="chapter in user_chapters"
                    :key="chapter.id"
                    class="p-2 align-items-center"
                  >
                    <div class="CP-container">
                      <div class="CP-title">
                        <p>{{ chapter.title[view_lang] }}</p>
                        <b-form-checkbox
                          v-model="chapter.sbloccato_utente"
                          switch
                          size="lg"
                        ></b-form-checkbox>
                      </div>
                      <div v-if="chapter.embed_contenuto_formativo">
                        <div
                          class="CP-embed-video"
                          v-html="chapter.embed_contenuto_formativo"
                        ></div>
                      </div>
                    </div>
                    <!-- <box-capitolo-learning
                    @cambioSbloccoCapitolo="cambioSblocco"
                    :chapter="chapter"
                  ></box-capitolo-learning> -->
                    <!-- <b-col>
                    <b-form-checkbox v-model="chapter.sbloccato_utente" switch>
                      <strong class="ml-3">
                        {{ chapter.title[view_lang] }}
                      </strong>
                    </b-form-checkbox>
                  </b-col> -->
                  </b-row>
                </div>
              </b-form-group>
              <b-button
                squared
                variant="primary"
                size="lg"
                class="big-button mt-2 mb-5"
                @click="saveUserSection"
                >Save user section</b-button
              >
            </b-overlay>
          </b-tab>
          <b-tab v-if="currentItem" title="Target" class="mx-3 mt-4">
            <b-form-group label="Campagna">
              <b-form-select
                @change="loadTargetTab"
                v-model="user_campaign"
                :options="computed_user_campaigns"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Progress" class="mt-4">
              <b-progress
                :value="computed_percentualeCompletmento"
                max="100"
                variant="primary"
                height=".5rem"
                class="progress-bar-wrapper"
              ></b-progress>
            </b-form-group>
            <div v-if="user_kpis.length > 0" class="d-flex flex-wrap mt-5">
              <kpi-widget
                v-for="kpi in computed_user_kpis"
                :key="kpi.text"
                :item="kpi"
              ></kpi-widget>
            </div>
            <div
              v-if="computed_user_badges.length > 0"
              class="d-flex flex-wrap mt-4"
            >
              <div class="w-100">
                <h2>Badges</h2>
              </div>
              <badge-award-widget
                v-for="badge in computed_user_badges"
                :key="badge.text"
                :item="badge"
              >
              </badge-award-widget>
            </div>

            <div
              v-if="computed_user_awards.length > 0"
              class="d-flex flex-wrap mt-4"
            >
              <div class="w-100">
                <h2>Awards</h2>
              </div>
              <badge-award-widget
                v-for="award in computed_user_awards"
                :key="award.text"
                :item="award"
              >
              </badge-award-widget>
            </div>
          </b-tab>
          <b-tab v-if="currentItem" title="Talent Analysis" class="mx-3">
            <TalentAnalysisReport
              :idUser="tmpItem.id"
              :isTab="true"
              :showCheckboxes="false"
            />
          </b-tab>
          <b-tab
            v-if="current_company.nome == 'Yoube Cosmetics'"
            title="Personal Setup"
            class="mx-3 mt-4"
          >
            <div class="personalsetup-list">
              <div
                v-for="item in personalsetupItems"
                :key="item.label"
                class="personalsetup-list-item mb-2"
              >
                <div class="personalsetup-list-item-label">
                  <b-form-group label="Nome">
                    <b-form-input v-model="item.nome" />
                  </b-form-group>
                </div>
                <div class="personalsetup-list-item-distribuita">
                  <b-form-group label="Perc. Distribuita">
                    <b-form-input v-model="item.perc_distribuzione" />
                  </b-form-group>
                </div>
                <div class="personalsetup-list-item-ricevuta">
                  <b-form-group label="Perc. Ricevuta">
                    <b-form-input v-model="item.perc_ricevuta" />
                  </b-form-group>
                </div>
              </div>
              <div class="personalsetup-list-button">
                <b-button>Salva</b-button>
              </div>
            </div>
          </b-tab>
          <b-tab
            v-if="current_company.nome == 'Axerta'"
            title="Caratteristiche e strumentazione del Cie"
            class=" mt-4"
          >
            <CaratteristicheEStrumentazioneDelCie
              :idUtente="tmpItem.id"
            ></CaratteristicheEStrumentazioneDelCie>
          </b-tab>
          <!-- <b-tab
            v-if="current_company.nome == 'Yoube Cosmetics'"
            title="Dati Yoube"
            class="mx-3 mt-4"
          >
            <DatiYoube :idUtente="tmpItem.id"></DatiYoube>
          </b-tab> -->
        </b-tabs>
      </div>
    </b-sidebar>
    <b-button class="fixed-button py-2" v-b-toggle.sidebar-network>
      <img src="../assets/images/network.png" class="img-fluid" />
    </b-button>
    <b-sidebar class="right-sidebar" id="sidebar-network" right shadow lazy>
      <network
        @edit-user="editUser"
        :list="items"
        :companies="companyList"
        :roles="roleTypeList"
      />
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetSidebar"
      @ok="deleteItem(currentItem.id)"
      >Do you really want to delete the selected element?</b-modal
    >
  </div>
</template>

<script>
import KpiWidget from "../components/KpiWidget.vue";
import BadgeAwardWidget from "../components/BadgeAwardWidget.vue";
import Network from "../components/Network.vue";
import AxiosService from "./../axiosServices/AxiosService";
import TalentAnalysisReport from "./TalentAnalysisReport.vue";
import CaratteristicheEStrumentazioneDelCie from "../components/axerta/CaratteristicheEStrumentazioneDelCie.vue";
import { mapGetters } from "vuex";
// import DatiYoube from "../components/DatiYoube.vue"
export default {
  components: {
    KpiWidget,
    BadgeAwardWidget,
    Network,
    TalentAnalysisReport,
    CaratteristicheEStrumentazioneDelCie,
  },
  data() {
    return {
      current_company: null,
      showTabOverlay: false,
      items: null,
      fields: [
        { key: "nome", label: "Name" },
        { key: "cognome", label: "Surname" },
        { key: "email", label: "Email" },
        { key: "company", label: "Company" },
        { key: "area", label: "Area" },
        { key: "department", label: "Department" },
        { key: "cluster", label: "Cluster" },
        { key: "role", label: "Role" },
        { key: "actions", label: "Actions" },
        { key: "attivo", label: "Active" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      advancedFilter_visible: false,

      currentItem: null,
      tmpItem: {},

      companyList: [],
      companyListSelected: 0,

      roleTypeList: [],
      roleTypeListSelected: 0,

      areaTypeList: [],
      areaTypeListSelected: 0,

      departmentTypeList: [],
      departmentTypeListSelected: 0,

      clusterTypeList: [],
      clusterTypeListSelected: 0,

      selectedKpi: "",

      file_photo: null,
      loadingFile: false,

      user_campaign: null,
      user_campaigns: [
        {
          id: 0,
          title: {
            "it-IT": "Seleziona Campagna",
            "en-EN": "Select Campaign",
          },
        },
      ],

      user_section: null,
      user_sections: [
        {
          id: 0,
          name: { "it-IT": "Seleziona Sezione", "en-EN": "Select Section" },
        },
      ],

      user_chapters: [],

      isLoading: false,
      view_lang: null,

      user_kpis: [],
      user_badges: [],
      user_awards: [],

      tuttiCapitoliSelezionati: false,

      showDeleteModal: false,

      personalsetupItems: [
        { id: 1, nome: "Booking", perc_distribuzione: 1, perc_ricevuta: 0.5 },
        {
          id: 2,
          nome: "Accademy",
          perc_distribuzione: 3,
          perc_ricevuta: 1.5,
        },
        {
          id: 3,
          nome: "Ambassador",
          perc_distribuzione: 2,
          perc_ricevuta: 1,
        },
        { id: 4, nome: "Open Day", perc_distribuzione: 2, perc_ricevuta: 1 },
        {
          id: 5,
          nome: "Degustazioni",
          perc_distribuzione: 1,
          perc_ricevuta: 0.5,
        },
      ],
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("User");
    this.resetSidebar();
    this.current_company = this.company;
    console.log(
      "🚀 ~ file: Users.vue:618 ~ created ~ this.current_company:",
      this.current_company
    );
  },
  mounted() {
    this.service.read().then((data) => {
      this.items = data;
    });

    this.service
      .readCustomEndpoint("AdvancedTargeting/GetAreas")
      .then((res) => {
        res.forEach((element) => {
          element.text = element.nome;
          element.value = element.id;

          this.areaTypeList.push(element);
        });
      });

    this.service
      .readCustomEndpoint("AdvancedTargeting/GetDepartments")
      .then((res) => {
        res.forEach((element) => {
          element.text = element.nome;
          element.value = element.id;

          this.departmentTypeList.push(element);
        });
      });

    this.service
      .readCustomEndpoint("AdvancedTargeting/GetClusters")
      .then((res) => {
        res.forEach((element) => {
          element.text = element.nome;
          element.value = element.id;

          this.clusterTypeList.push(element);
        });
      });

    this.service.readCustomEndpoint("Role").then((res) => {
      res.forEach((element) => {
        element.text = element.nome;
        element.value = element.id;

        this.roleTypeList.push(element);
      });
    });
    // this.service
    //   .getOptions("UserRole")
    //   .then((data) => (this.roleTypeList = data));
    this.service
      .getOptions("Company")
      .then((data) => (this.companyList = data));
  },
  methods: {
    resetSidebar() {
      this.currentItem = null;
      this.tmpItem = {
        nome: "",
        cognome: "",
        email: "",
        avatar_url: "",
        social: {},
        id_company: 0,
        id_parent: 0,
        id_livello: 0,
        id_role: 0,
        id_area: 0,
        id_department: 0,
        id_cluster: 0,
        permalink: "",
        target: 0,
        attivo: true,
      };
      this.file_photo = null;
      this.view_lang = this.default_lang.value || this.default_lang;
      this.user_kpis = [];
      this.user_badges = [];
      this.user_awards = [];
      this.user_chapters = [];
      this.user_sections = [
        {
          id: 0,
          name: { "it-IT": "Seleziona Sezione", "en-EN": "Select Section" },
        },
      ];
      this.user_campaigns = [
        {
          id: 0,
          title: {
            "it-IT": "Seleziona Campagna",
            "en-EN": "Select Campaign",
          },
        },
      ];
      this.tuttiCapitoliSelezionati = false;
    },

    editUser(id) {
      this.resetSidebar();
      let item = this.items.find((x) => x.id === id) || null;
      if (item) {
        this.currentItem = item;
        this.tmpItem = JSON.parse(JSON.stringify(item));
        this.$root.$emit("bv::toggle::collapse", "sidebar-partners");
      }
    },
    deleteItem(id) {
      this.service
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
    getRoleName(idRole) {
      const role = this.roleTypeList.find((x) => x.value === idRole);
      return role ? role.text : "";
    },
    getAreaName(idArea) {
      const area = this.areaTypeList.find((x) => x.value === idArea);
      return area ? area.text : "";
    },
    getDepartmentName(idDepartment) {
      const department = this.departmentTypeList.find(
        (x) => x.value === idDepartment
      );
      return department ? department.text : "";
    },
    getClusterName(idCluster) {
      const cluster = this.clusterTypeList.find((x) => x.value === idCluster);
      return cluster ? cluster.text : "";
    },
    getCompanyList(idCompany) {
      const company = this.companyList.find((x) => x.value === idCompany);
      return company ? company.text : "";
    },
    backgroundImage() {
      return this.file_photo_url || this.tmpItem.avatar_url;
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function(word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
    },

    async saveItem() {
      this.isLoading = true;
      this.loadingFile = true;
      let item = { ...this.tmpItem };

      item.nome = this.titleCase(item.nome);
      item.cognome = this.titleCase(item.cognome);

      const validationObject = this.$validate("user", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        this.isLoading = false;
        return false;
      }

      if (this.file_photo) {
        if (this.file_photo.size > 3 * 1024 * 1024) {
          alert("I file non possono superare il massimo di 3MB");
          return;
        }
        const formData = new FormData();
        formData.append("pic", this.file_photo);
        let imageName = await this.service
          .uploadFile(formData)
          .then((res) => res.data)
          .finally(() => {
            this.loadingFile = false;
          });
        let imageUrl = this.$driveAddress + imageName;
        item.avatar_url = imageUrl.replaceAll(" ", "%20");
      }

      if (item.id) {
        this.service
          .update(item)
          .then((response) => {
            if (response.id == item.id) {
              Object.keys(this.currentItem).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentItem[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-partners");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.service
          .create(item)
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.items.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-partners");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    updateState(item) {
      item.attivo = !item.attivo;
      this.service.update(item).then((response) => {
        if (response.id == item.id) {
          this.$successToast();
        } else {
          this.$errorToast();
          item.attivo = !item.attivo;
          return false;
        }
      });
    },
    loadUserData() {
      this.user_campaigns.slice(1, 0);
      this.user_campaign = null;

      this.service
        .readCustomEndpoint(
          "CampaignManager/GetUserCampaings",
          this.currentItem.id
        )
        .then((data) => {
          data.forEach((element) => {
            this.user_campaigns.push(element);
          });

          this.user_campaigns.length > 1
            ? (this.user_campaign = this.user_campaigns[0].id)
            : null;
          this.user_sections.splice(1);
          this.user_section = this.user_sections[0].valore;
          this.loadUserSections();
          // this.loadTargetTab();
        });
    },
    resetTargetTab() {
      this.user_kpis.length = 0;
      this.user_badges.length = 0;
      this.user_awards.length = 0;
    },
    async loadTargetTab() {
      await this.resetTargetTab();
      //Load KPIS
      this.service
        .readCustomEndpoint(
          "CampaignManager/GetUserKpiById/" +
            this.user_campaign +
            "/" +
            this.currentItem.id
        )
        .then((data) => {
          data.forEach((element) => {
            this.user_kpis.push(element);
          });
        });

      //Load Badges

      this.service
        .readCustomEndpoint(
          "CampaignManager/GetUserBadges/" +
            this.user_campaign +
            "/" +
            this.currentItem.id
        )
        .then((data) => {
          data.forEach((element) => {
            this.user_badges.push(element);
          });
        });

      //Load Awards

      this.service
        .readCustomEndpoint(
          "CampaignManager/GetUserAward/" +
            this.user_campaign +
            "/" +
            this.currentItem.id
        )
        .then((data) => {
          data.forEach((element) => {
            this.user_awards.push(element);
          });
        });
    },
    loadUserSections() {
      if (this.user_campaign) {
        this.showTabOverlay = true;
        this.service
          .readCustomEndpoint(
            "UserUtility/GetUserLearningCategories",
            this.user_campaign + "/" + this.currentItem.id
          )
          .then((data) => {
            this.user_sections = data;
            this.user_section = this.user_sections.length
              ? this.user_sections[0].id
              : null;
            // this.loadUserChapter();
            this.showTabOverlay = false;
          });
      } else {
        this.user_section = null;
      }
    },
    loadUserChapter() {
      this.user_chapters.splice(0);
      if (this.user_section) {
        this.showTabOverlay = true;
        this.service
          .readCustomEndpoint(
            "UserUtility/GetUserLearningChapters",
            this.user_campaign +
              "/" +
              this.user_section +
              "/" +
              this.currentItem.id
          )
          .then((data) => {
            data.forEach((element) => {
              element.id_campagna = this.user_campaign;
              element.id_user = this.currentItem.id;
              this.user_chapters.push(element);
            });
            // this.user_chapters = data;
            this.showTabOverlay = false;
          });
      } else {
        this.user_chapters = [];
      }
    },
    saveUserSection() {
      if (this.user_chapters.length) {
        this.service
          .putCustomEndpoint(
            "UserUtility/UpdateUserLearningChapters",
            this.user_campaign +
              "/" +
              this.user_section +
              "/" +
              this.currentItem.id,
            this.user_chapters
          )
          .then(() => {
            this.$successToast();
          })
          .catch(() => {
            this.$errorToast();
          });
      }
    },
    setNullRole() {
      this.tmpItem.id_role = 0;
      this.tmpItem.id_parent = 0;
    },
  },
  computed: {
    ...mapGetters(["languages", "company"]),
    ...mapGetters(["default_lang"]),

    totalRows() {
      return this.partnerList ? this.partnerList.length : 0;
    },
    partnerList() {
      if (this.items) {
        return this.items.filter((item) => {
          if (
            this.roleTypeListSelected &&
            item.id_role != this.roleTypeListSelected
          )
            return false;
          if (
            this.areaTypeListSelected &&
            item.id_area != this.areaTypeListSelected
          )
            return false;
          if (
            this.departmentTypeListSelected &&
            item.id_department != this.departmentTypeListSelected
          )
            return false;
          if (
            this.clusterTypeListSelected &&
            item.id_cluster != this.clusterTypeListSelected
          )
            return false;
          if (
            this.companyListSelected &&
            item.id_company != this.companyListSelected
          ) {
            return false;
          }
          return true;
        });
      } else {
        return [];
      }
    },
    partnerListParent() {
      if (this.items) {
        let parent_users = this.items
          .filter((item) => {
            if (this.tmpItem && item.id_company != this.tmpItem.id_company) {
              return false;
            }
            if (this.tmpItem && item.id == this.tmpItem.id) {
              return false;
            }
            return true;
          })
          .map((x) => {
            return { text: x.nome + " " + x.cognome, value: x.id };
          });
        parent_users.unshift({
          text: "No parent",
          value: 0,
        });
        return parent_users;
      } else {
        return [];
      }
    },
    filterRoleTypeList() {
      let array = [{ value: 0, text: "Select roles" }];

      this.roleTypeList.forEach((element) => {
        if (
          element.id_company == this.tmpItem.id_company ||
          element.id_company == this.companyListSelected
        ) {
          array.push(element);
        }
      });

      return array;

      // return [{ value: null, text: "All roles" }, ...this.roleTypeList];
    },

    filterAreaTypeList() {
      let array = [{ value: 0, text: "Select Areas" }];

      this.areaTypeList.forEach((element) => {
        if (
          element.id_company == this.tmpItem.id_company ||
          element.id_company == this.companyListSelected
        ) {
          array.push(element);
        }
      });

      return array;

      // return [{ value: null, text: "All roles" }, ...this.roleTypeList];
    },

    filterDepartmentTypeList() {
      let array = [{ value: 0, text: "Select Department" }];

      this.departmentTypeList.forEach((element) => {
        if (
          element.id_company == this.tmpItem.id_company ||
          element.id_company == this.companyListSelected
        ) {
          array.push(element);
        }
      });

      return array;

      // return [{ value: null, text: "All roles" }, ...this.roleTypeList];
    },

    filterClusterTypeList() {
      let array = [{ value: 0, text: "Select Cluster" }];

      this.clusterTypeList.forEach((element) => {
        if (
          element.id_company == this.tmpItem.id_company ||
          element.id_company == this.companyListSelected
        ) {
          array.push(element);
        }
      });

      return array;

      // return [{ value: null, text: "All roles" }, ...this.roleTypeList];
    },
    filterCompanyList() {
      return [{ value: 0, text: "Select company" }, ...this.companyList];
    },
    file_photo_url() {
      return this.file_photo ? URL.createObjectURL(this.file_photo) : "";
    },
    computed_percentualeCompletmento() {
      let perc = 0;
      this.user_campaigns.forEach((element) => {
        this.user_campaign == element.id
          ? (perc = element.percentualeCompletamento)
          : 0;
      });

      return perc;
    },
    computed_user_campaigns() {
      return this.user_campaigns.map((x) => ({
        value: x.id,
        text: x.title[this.view_lang],
      }));
    },
    computed_user_sections() {
      return this.user_sections.map((x) => ({
        value: x.id,
        text: x.name[this.view_lang],
      }));
    },
    computed_user_kpis() {
      return this.user_kpis.map((x) => ({
        text: x.friendlyName["it-IT"],
        point: x.valore,
        target: x.goal,
      }));
    },
    computed_user_badges() {
      return this.user_badges.map((x) => ({
        text: x.title["it-IT"],
        image: x.url_image,
        status: x.vinto,
      }));
    },
    computed_user_awards() {
      return this.user_awards.map((x) => ({
        text: x.title["it-IT"],
        image: x.url_image,
        status: x.vinto,
      }));
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
    tuttiCapitoliSelezionati: {
      handler() {
        this.user_chapters.forEach((element) => {
          element.sbloccato_utente = this.tuttiCapitoliSelezionati;
        });
      },
    },
  },
};
</script>
